import { default as deposit_45feedbackTZ0iJ41BZ3Meta } from "/app/pages/form/deposit-feedback.vue?macro=true";
import { default as returnuw4YrIdJ1jMeta } from "/app/pages/form/return.vue?macro=true";
import { default as sp8_45feedback_4524xBz8cmh88Meta } from "/app/pages/form/sp8-feedback-2.vue?macro=true";
import { default as sp8_45feedbackxqBeCPQ4QmMeta } from "/app/pages/form/sp8-feedback.vue?macro=true";
export default [
  {
    name: "form-deposit-feedback",
    path: "/form/deposit-feedback",
    meta: deposit_45feedbackTZ0iJ41BZ3Meta || {},
    component: () => import("/app/pages/form/deposit-feedback.vue")
  },
  {
    name: "form-return",
    path: "/form/return",
    meta: returnuw4YrIdJ1jMeta || {},
    component: () => import("/app/pages/form/return.vue")
  },
  {
    name: "form-sp8-feedback-2",
    path: "/form/sp8-feedback-2",
    meta: sp8_45feedback_4524xBz8cmh88Meta || {},
    component: () => import("/app/pages/form/sp8-feedback-2.vue")
  },
  {
    name: "form-sp8-feedback",
    path: "/form/sp8-feedback",
    meta: sp8_45feedbackxqBeCPQ4QmMeta || {},
    component: () => import("/app/pages/form/sp8-feedback.vue")
  }
]